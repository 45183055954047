<template>
    <header class="side-bar-header">
        <slot />
    </header>
</template>

<style lang="scss">
.side-bar-header {
    text-align: center;
}
</style>
